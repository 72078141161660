:root {
  --font-default: 'Manrope', sans-serif;
  --font-secondary: 'Handlee', cursive;
  --fontawesome: "Font Awesome 5 Pro";
  --black: #000000;
  --dark: #1f4e3d;
  --white: #ffffff;
  --color-primary: #49a760;
  --color-secondary: #f7c35f;
  --color-heading: #04000b;
  --color-paragraph: #787878;
  --box-shadow-primary: -1px 3px 10px 0 rgba(0, 0, 0, 0.6);
  --box-shadow-secondary: 0 10px 30px 0 rgba(44, 130, 237, 0.4);
  --box-shadow-regular: 0px 2px 12px 0px #e7e7e7;
  --bg-gray: #eff2f5;
  --bg-gradient: linear-gradient(97deg, #49a760 50%, rgba(252, 197, 27, 1) 100%);
}
body {
  font-family: var(--endreox-font);
  color: var(--endreox-gray);
  font-size: 14px;
  line-height: 30px;
  font-weight: 400;
  margin: 0;
  padding: 0;

}

h2 {
  font-size: 36px;
}

.btn,
a,
button {
  /* font-weight: 900; */
  outline: medium none;
  text-decoration: none;
  transition: all .35s ease-in-out;
}

#main-content {
  /* margin-left: 250px; */
  padding-bottom: 50px;
}
.MuiToolbar-gutters.MuiToolbar-regular {
  justify-content: space-between;
}

.MuiAppBar-colorPrimary {
  background-color: #010820 !important;
}

.MuiDrawer-paper.MuiDrawer-paperAnchorLeft {
  background-color: #010820 !important;

}

ul.MuiList-root.MuiList-padding a {

  color: white;
  width: 100%;
}

.MuiSvgIcon-fontSizeMedium {
  /* color: white !important; */
}

ul.MuiList-root.MuiList-padding a:hover svg {
  transition: 0.6s;

  /* color: #010820; */
}

ul.MuiList-padding {
  display: grid;
  gap: 2px;
}



ul.MuiList-root.MuiList-padding a.active,
ul.MuiList-root.MuiList-padding a:hover {
  background-color: var(--color-secondary) !important;
  color:white;
}

.MuiListItemIcon-root {
  color: white !important;
  min-width: 30px !important;
}

.cardHeader_section {
  background-color: var(--color-secondary);
}

.Add_btn_,
.Add_btn_:hover,
.Add_btn_:focus {
  background-color: var(--dark) !important;
  border-color: var(--dark) !important;
  color: white !important;
}

.rdt_Table {
  border: 1px solid #d3d3d3;
}

.main {
  padding: 1rem;
}

.selectoption {
  display: flex;
  gap: 20px;
  padding-left: 1rem;
}

.ewkixa {
  display: flex;
  flex-direction: column;
}
.sticky_navBar{
  position: sticky;
  top: 0;
  /* padding: 5px 0px; */
  /* border : 1px solid lightgray; */
  display: flex;
  align-items: center;
  /* border-bottom: 1px solid lightgray; */
  z-index: 1000;
  /* background:linear-gradient(236deg, rgba(2,0,36,1) 0%, rgba(0,212,255,1) 40%, rgba(153,147,213,1) 84%); */
  background-color: #010820;
}
.nav_bar{
  display: flex;
  justify-content: space-between;
  align-items: center;
 padding: 0px 5px;
}

.ps-menu-icon{

  color: #42add3;

}
.nav_tabs{  
  display: flex;
  gap: 30px;
  align-items: center;
  color: #fff;
  /* background-color: #F2FAFF; */
}
.nav_tabs ul {
  display: flex;
  gap: 15px;
  margin: 0;
}
.nav_Links ul{
  display: flex;
  gap: 10px;
}
.bglogo{
  background-color: white;


}

.sc-dkrFOg.sidebar-inner{
  background-color: white;
}
hr{
  margin: 10px 0px;
}
.lkTtqM:hover{
  color: #fff;
}
.lkTtqM a:hover{
  color:#fff;
}
.card-header.cardHeader{
  background-color:  #C9C9C9;
}

.collapse_btn{
  display: none;
}



@media only screen and (max-width : 991.98px){
  .collapse_btn{
      display: block;
  }
  
}

@media only screen and (max-width : 767.98px){
  #main-content{
    margin-left: 0;
  }
  
}






a,
abbr,
acronym,
address,
applet,
b,
big,
blockquote,
body,
button,
caption,
center,
cite,
code,
dd,
del,
dfn,
div,
dl,
dt,
em,
fieldset,
font,
footer,
form,
h1,
h2,
h3,
h4,
h5,
h6,
header,
html,
i,
iframe,
img,
ins,
kbd,
label,
legend,
li,
nav,
object,
ol,
p,
pre,
q,
s,
samp,
section,
small,
span,
strike,
strong,
sub,
sup,
table,
tbody,
td,
tfoot,
th,
thead,
title,
tr,
tt,
u,
ul,
var {
  background: transparent;
  border: 0;
  margin: 0;
  outline: 0;
  padding: 0;
  scroll-behavior: smooth;
  vertical-align: initial;
}

.rdt_Table .bQdYbR {
  min-width: 200px;
}

.MuiBox-root.css-k008qs {
  display: -webkit-box;
}
.modal{
  z-index: 9999;
}
.Toastify {
  z-index: 9999;
}

.errMsg {
  font-size: 12px;
  color: red;
}

.mySpinner {
  position: fixed;
  top: 50%;
  left: 50%;
  color: #010820;
  z-index: 999;

}
.wrapper {
  display: inline-block;
  width: 100%;
  margin: 20px auto 0;
  padding: 15px;
}
.addcareer{
  display: flex;
  justify-content: end;
  gap:10px;
}
.Add_btn_{
  float: right;
  /* margin: auto; */
  background-color: var(--dark);
  color: #fff;
  border: none;
}
.Add_btn_:hover{
  background-color: var(--dark);
}
.submit_btn_:hover{
  background-color: #475667;

}

.select_status{
  flex: 0 0 auto;
  background: var(--dark);
  position: relative;
  font-family: Arial;
  color: white;
  height: 30px;
  width: auto;
  border: 2px solid transparent;
  border-radius: 4px;
}
._edit_icons:hover,
._edit_icons{
  background-color: var(--color-secondary);
  border: none;
}
._delete_btn_:hover,
._delete_btn_{
  background-color: #BF0C0C;
  border: none;
}


/* a:hover{
  color: var(--dark);
} */

.lehLbj:hover{
  background: none;
}

.rdt_Table{
border: 1px solid lightgray;

}

.main{
  padding: 1rem;
}

.rdt_TableHeadRow,
.rdt_TableRow{
  font-size: 16px;
}

.dashboard_card{
  display: flex;
  gap: 40px;
  flex-wrap: wrap;
  justify-content: center;
}

.dashboard_card .card{
  border: none;
  background-color: #fff;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  color: var(--dark);
  border-radius: 20px;
}

.dashboard_card .card .card-body{
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: space-around;
  flex-wrap: wrap;
  gap: 16px;

}



.dashboard_card .card .card-body .card-title{
  font-size: 20px;
  margin: 0px;
  color: var(--dark);
}

.c_icon{
  font-size: 50px;
  color: var(--dark);
}

.rdt_TableRow:hover{
 background-color: #a7b4c1;
  color: black;
}

.jxvJXu,
.rdt_Pagination{
  color: var(--dark) !important;
  fill: var(--dark) !important;
}
.jxvJXu:disabled {
  cursor: unset;
  color: rgba(0,0,0,.18) !important;
  fill: rgba(0,0,0,.18) !important;
}


._eye_icon{
  position: absolute;
  right: 17px;
  background: lightgrey;
  font-size: 20px;
  padding: 5px 10px;

}

.selectoption{
  padding-left: 1rem;
  display: flex;
  gap: 20px;
}

.pdf_preview{
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  gap: 20px;

}
.pdf_preview > button:hover,
.pdf_preview > button{
  background-color: lightgray;
  color: #fff;
  border: none;
  
}

.search_d{
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.search_d input{
  padding: 0.3rem;
  border-radius: 10px;
  border : 2px solid darkgray;
  outline : none;
}

@media only screen and (max-width: 991.98px){
.admin_main {
  margin-left: 0!important;
}
.MuiDrawer-root.MuiDrawer-docked {
  position: absolute;
  z-index: 999;
}
}

.navbartext{
  text-decoration: none;
  color: #164f77;
}


.active{

  font-weight: bold;

}
._bg_ .sidebar-inner{
  /* background:linear-gradient(236deg, rgba(2,0,36,1) 0%, rgba(0,212,255,1) 40%, rgba(153,147,213,1) 84%); */
  background-color: #164f77;
  padding-bottom: 80px;
 

}

.svg{

  color: #164f77 !important   ;

}

.sidebar .sub-menu-content{
  background-color: #164f77 !important;
}

.fpTHfu::-webkit-scrollbar {
  display: none;
}

.fpTHfu{
  -ms-overflow-style: none; 
  scrollbar-width: none; 
}

.menu-anchor:hover{
  background: #fff !important;
  border-radius: 20px 0px 0px 20px !important;
  color: #164f77 !important;
}

.menu-anchor span > a{
  color: #fff !important;
}
.menu-anchor:hover span > a{
  color: #164f77 !important;
}


/*  style={{ display: 'flex', height: '100%', position: 'fixed', overflow: 'hidden' }} */
.icon-button__badge {
  width: 20px;
  height: 20px;
  background: #BF0C0C;
  color: #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  font-size: 12px;
}

._bg{
   
  background: radial-gradient(circle, #1f4e3da6, #1f4e3d, #1f4e3d, #1f4e3d, #1f4e3d, #1f4e3d, #1f4e3d, #1f4e3d,#1f4e3d, #1f4e3d
  , #1f4e3d, #1f4e3d); 
  /* background: rgb(247,195,95);
background: radial-gradient(circle, rgba(247,195,95,1) 0%, rgba(31,78,61,1) 67%); */
    height:100vh;
    background-position: center;
    background-size: cover;
  }
  
  .main_div{
    display:flex;
    align-items:center;
    justify-content:center;
    position: relative;
   /* left: 50%; */
   top: 25%;
   /* transform: translate(-50%, -50%);
    width:100%; */
  }
    .form-box{
        width:380px;
        height:auto;
        background-color: #f2faff;
        padding:20px;
        display:flex;
        flex-direction:column;
        justify-content:flex-start;
        /* opacity:0.75; */
        border-radius:2%;
    }

    #basic-addon1{
        font-size: 24px;
        border-radius: 0;
    }
    .adminpass.modal-header{
        border-bottom: 0;
    }
    button.forget{
       float: right;
    }

.form-control.user{
  border-radius: 0;
  width: 106%;
}
.emailvalidation{
  color: red;
  font-size: large;
}
.forpass a{
  text-decoration: none;
  color: #b34d61;
}
.forpass a:hover{
 
  color: #b34d61;
}
.btn-secondary:focus-visible {
  background-color: #b34d61;
}

.login_btn{
  width: fit-content;
  margin: auto;
  padding-bottom: 10px;
}

.input-group-text{
  padding: 12px 0.75rem;
}
.login_btn button{
  background-color: var(--dark);
  color: #fff;
  border: none;
}

.login_btn button:hover{
  background-color: var(--dark);

}

.input-group-text{
  color: var(--dark);
}

.submit_btn_{
  float: right;
  border: none;
  color: #fff;
  background-color: var(--dark);  

}

.submit_btn_ button:hover{
  background-color: var(--dark); 
}

._profile img{
  padding: 1rem;
}

.submit_btn_:hover{
  background-color: var(--dark);  
}